import create from 'zustand'

export const useAppStore = create(set => ({
    token: null,
    currentUser: null,
    isAuthenticated: false,
    role: undefined,
    step: undefined,
    addToken: (jwt) => set(state => ({token: jwt, isAuthenticated: true})),
    logout: () => set(state => ({token: null, isAuthenticated: false, currentUser: null})),
    addUser: (user) => set(state => ({currentUser: user})),
    setRole: (role) => set(state => ({role: role})),
    notification: {
        type: 'success',
        show: false,
        title: '',
        body: '',
    },
    showNotification: (type, title, body,changeStep,step) => set(state => ({
        notification: {
            show: true,
            type: type,
            title: title,
            body: body,
        },
        step: step,
        changeStep : changeStep
    })),
    hideNotification: () => set(state => ({
        notification: {
            show: false,
            title: '',
            body: '',
        }
    })),
    toggleNotification: (value) => set(state => ({show: value})),
}))
