export const save = (key, value) => localStorage.setItem(key, value);
export const get = (key) => localStorage.getItem(key);
export const remove = (key) => localStorage.removeItem(key);
export const destroy = () => localStorage.clear();


export const saveToken = (token) => save('token', token);
export const getToken = () => get('token');
export const getProfile = () => get('user');
export const saveProfile = (profile) => save('user', profile);

export const removeUserInfoFromStorage = () => {
    remove('token');
    remove('user');
}
