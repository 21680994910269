import {BrowserRouter, Route, Switch, useHistory} from "react-router-dom";
import {lazy, Suspense, useEffect} from "react";
import * as routes from "./shared/utils/const";
import {QueryClient, QueryClientProvider,} from 'react-query'
import AppNotification from "./shared/components/notifications/AppNotification";
import {get} from "./shared/utils/LocaleStorage";
import {useAppStore} from "./shared/appStore/useAppStore";
import {setAuthorizationHeaderToAllRequests} from "./api/Api";

const LoginPage = lazy(() => import("./routes/landing/login/LoginPage"));
const SignUpPage = lazy(() => import("./routes/landing/signup/SignUpPage"));
const FirstLoginPage = lazy(() => import("./routes/landing/firstLogin/FirstLoginPage"))
const HomePage = lazy(() => import("./routes/landing/home/HomePage"));
const ResetPage = lazy(() => import("./routes/landing/reset/ResetPage"));
const SuccessPage = lazy(() => import("./routes/landing/success/SuccessPage"));
const DoctorArea = lazy(() => import("./routes/doctor/DoctorArea"));
const PharmacyArea = lazy(() => import("./routes/pharmacy/PharmacyArea"));
const LaboArea = lazy(() => import("./routes/labo/LaboArea"));
const NotFound = lazy(() => import("./routes/shared/errors/NotFoundPage"));

const queryClient = new QueryClient()

function App() {
    const history = useHistory();
    const addUser = useAppStore(state => state.addUser);
    const addToken = useAppStore(state => state.addToken);
    const saveRole = useAppStore(state => state.setRole);

    useEffect(() => {
        const token = get('token');
        const role = get('role');
        const user = JSON.parse(get('user'));
        if (token) addToken(token);
        if (user) addUser(user);
        if (role) saveRole(role);
        setAuthorizationHeaderToAllRequests(token);
    });

    return (
        <>
            <QueryClientProvider client={queryClient}>
                <BrowserRouter history={history}>
                    <Suspense fallback={<center>loading</center>}>
                        <Switch>
                            <Route path={routes.DOCTOR_DASHBOARD_HOME_LINK} component={DoctorArea}/>
                            <Route path={routes.PHARMACY_DASHBOARD_HOME_LINK} component={PharmacyArea}/>
                            <Route path={routes.LABO_DASHBOARD_HOME_LINK} component={LaboArea}/>
                            <Route exact path={routes.PUBLIC_LOGIN_LINK} component={LoginPage}/>
                            <Route exact path={routes.PUBLIC_SIGNUP_LINK} component={SignUpPage}/>
                            <Route exact path={routes.PUBLIC_FIRST_LOGIN_LINK} component={FirstLoginPage}/>
                            <Route exact path={routes.PUBLIC_RESET_LINK} component={ResetPage}/>
                            <Route exact path={routes.PUBLIC_SUCCESS_LINK} component={SuccessPage}/>
                            <Route exact path={routes.PUBLIC_HOME_LINK} component={HomePage}/>
                            <Route component={NotFound}/>
                        </Switch>
                    </Suspense>
                </BrowserRouter>
                <AppNotification/>
            </QueryClientProvider>
        </>
    );
}

export default App;
