/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from "react";
import { Transition } from "@headlessui/react";
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  XCircleIcon,
} from "@heroicons/react/outline";
import { XIcon } from "@heroicons/react/solid";
import { useAppStore } from "../../appStore/useAppStore";

const notificationTypes = {
  success: (
    <CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />
  ),
  error: <XCircleIcon className="h-6 w-6 text-red-400" aria-hidden="true" />,
  warning: (
    <ExclamationCircleIcon
      className="h-6 w-6 text-yellow-400"
      aria-hidden="true"
    />
  ),
  validation: (
    <ExclamationCircleIcon
      className="h-6 w-6 text-yellow-400"
      aria-hidden="true"
    />
  ),
};

export default function AppNotification() {
  const {
    notification: { show, type, title, body },
    hideNotification,changeStep,step
  } = useAppStore((state) => state);
  return (
    <>
      <div
        aria-live="assertive"
        className="fixed top-0 z-50 -top-2 inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
      >
        <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={show}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">{notificationTypes[type]}</div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">{title}</p>

                    {typeof body === "object" ? (
                      body.map((b, i) => (
                        <p key={i} className="mt-1 text-sm text-gray-500">
                          {b}
                        </p>
                      ))
                    ) : (
                      <p className="mt-1 text-sm text-gray-500">{body}</p>
                    )}
                  </div>
                  <div className="ml-4 flex-shrink-0 flex">
                    <button
                      className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      onClick={hideNotification}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
                {type === "validation" ? (
                      <div className="flex justify-end mt-2 mr-6" >
                        <button 
                        className="px-2 bg-white text-sm text-blue-500" 
                        onClick={async () =>{ 
                        changeStep(step)  ;
                        hideNotification()}
                        }>
                          OK
                        </button>
                        <button className="px-2 bg-white text-sm text-gray-500" onClick={() =>{
                        hideNotification()
                    }
                        }>
                          Annuler
                        </button>
                      </div>
                    ) : (
                      <div></div>
                    )}
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  );
}
