import axios from 'axios';
import {get, removeUserInfoFromStorage} from "../shared/utils/LocaleStorage";

export const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;
export const wsApiEndpoint = process.env.REACT_APP_WS_API_ENDPOINT;
export const storageEndpoint = process.env.REACT_APP_STORAGE_ENDPOINT;

export const getApi = (serviceUrl, headers = {}) => {
    const instance = axios.create({
        baseURL: `${apiEndpoint}/${serviceUrl}`,
        headers: headers,
    });

    instance.interceptors.request.use(async config => {
        const token = get('token');
        config.headers = {
            'Authorization': `bearer ${token}`,
            'Accept': 'application/json',
            ...config.headers,
        }
        return config;
    }, error => Promise.reject(error));

    instance.interceptors.response.use(response => response, error => {
        if (error?.response?.status === 401
            ||
            error?.response?.data?.errors === 'Error session expired please login') {
            removeUserInfoFromStorage();
            document.location.href = `${document.location.origin}/login`;
        }
        return Promise.reject(error);
    });
    return instance;
};
export const setAuthorizationHeaderToAllRequests = (token) => axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
